import { Grid } from "@/common/styles/Grid";
import { Helmet } from "react-helmet-async";
import Button from "@kiwicom/orbit-components/lib/Button";
import Heading from "@kiwicom/orbit-components/lib/Heading";
import Loading from "@kiwicom/orbit-components/lib/Loading";
import { getUsers } from "@/modules/Users/services/usersService";
import { UserItem } from "@/modules/Users/components/UserItem/UserItem";
import { useGetPaginatedData } from "@/common/hooks/useGetPaginatedData";

export function UsersList() {
  const { isLoading, error, items: users, loadMore } = useGetPaginatedData(getUsers, null, 5);

  if (error) {
    throw error;
  }

  if (isLoading && !users.length) {
    return <Loading />;
  }

  const renderUsers = () => {
    return users.map((user) => <UserItem key={user.id} {...user} />);
  };

  return (
    <>
      <Helmet>
        <title>Users</title>
      </Helmet>
      <Heading spaceAfter="large" type="title2">
        Users
      </Heading>
      <Grid>{renderUsers()}</Grid>

      {!!users.length && (
        <div style={{ marginTop: "20px" }}>
          <Button loading={isLoading} onClick={loadMore} size="small">
            Load more
          </Button>
        </div>
      )}
    </>
  );
}
