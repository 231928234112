import { useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import Alert from "@kiwicom/orbit-components/lib/Alert";
import Button from "@kiwicom/orbit-components/lib/Button";
import Heading from "@kiwicom/orbit-components/lib/Heading";
import Loading from "@kiwicom/orbit-components/lib/Loading";
import { useGetUser } from "@/modules/Users/hooks/useGetUser";
import { PaginationControl } from "@/common/styles/Pagination";
import { uniqueTagsSelector } from "@/modules/Posts/selectors";
import { TagList } from "@/modules/Posts/components/TagList/TagList";
import { getPostsByUser } from "@/modules/Posts/services/postsService";
import Card, { CardSection } from "@kiwicom/orbit-components/lib/Card";
import { PostItem } from "@/modules/Posts/components/PostItem/PostItem";
import { useGetPaginatedData } from "@/common/hooks/useGetPaginatedData";

export function PostsByUser() {
  const { userId } = useParams<Record<string, string>>();
  const { user, error: getUserError, isLoading: getUserIsLoading } = useGetUser(userId);
  const userFullName = useMemo(() => (user ? `${user.firstName} ${user.lastName}` : ""), [user]);
  const { isLoading, error, items: posts, loadMore } = useGetPaginatedData(getPostsByUser, userId, 5);
  const tags = useMemo(() => uniqueTagsSelector(posts), [posts]);

  if (error || getUserError) {
    throw error;
  }

  if (!posts.length && !isLoading) {
    return <Alert type="info" title={`${userFullName} doesn't have posts.`} />;
  }

  // First loading state
  if (isLoading && !posts.length) {
    return <Loading></Loading>;
  }

  const renderPosts = () => posts.map((post) => <PostItem showOwner={false} key={post.id} {...post} />);

  return (
    <>
      <Helmet>
        <title>{userFullName}'s posts</title>
      </Helmet>

      {getUserIsLoading ? (
        <Loading type="inlineLoader" />
      ) : (
        <Heading spaceAfter="medium" type="title2">
          {userFullName}'s posts
        </Heading>
      )}

      <Card spaceAfter="medium">
        <CardSection title="Tags">
          <TagList tags={tags} />
        </CardSection>
      </Card>

      {renderPosts()}

      {!!posts.length && (
        <PaginationControl>
          <Button loading={isLoading} size="small" onClick={loadMore}>
            Load more
          </Button>
        </PaginationControl>
      )}
    </>
  );
}
