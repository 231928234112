import styled from "styled-components";
import { Link } from "react-router-dom";
import Tag from "@kiwicom/orbit-components/lib/Tag";

interface Props {
  tags: string[];
}

const Tags = styled.div`
  a {
    margin-right: 5px;
  }
`;

export function TagList({ tags }: Props) {
  const render = () =>
    tags.map((tag) => (
      <Link key={tag} to={`/posts/tags/${tag}`}>
        <Tag size="small">{tag}</Tag>
      </Link>
    ));

  return <Tags>{render()}</Tags>;
}
