import { http } from "@/common/utils/http";
import { Comment } from "@/modules/Posts/typings";
import { PostPreview } from "@/modules/Posts/typings/index";
import { PaginateResponse } from "@/common/typing/interfaces";

export async function getPostsByUser(limit: number, page: number, payload: unknown) {
  const params = { limit, page };
  const { data } = await http.get<PaginateResponse<PostPreview>>(`/user/${payload}/post`, { params });
  return data;
}

export async function getPostComments(postId: string) {
  const { data } = await http.get<PaginateResponse<Comment>>(`/post/${postId}/comment`, { params: { limit: 2 } });
  return { total: data.total, comments: data.data };
}

export async function getPostsByTag(limit: number, page: number, payload: unknown) {
  const params = { limit, page };
  const { data } = await http.get<PaginateResponse<PostPreview>>(`/tag/${payload}/post`, { params });
  return data;
}
