import { useParams } from "react-router";
import { Helmet } from "react-helmet-async";
import * as Dates from "@/common/utils/dates";
import { User } from "@/modules/Users/typings";
import Loading from "@kiwicom/orbit-components/lib/Loading";
import Heading from "@kiwicom/orbit-components/lib/Heading";
import { useGetUser } from "@/modules/Users/hooks/useGetUser";
import { ProfileDetails, Avatar, AccountDetails } from "./styles";
import Card, { CardSection } from "@kiwicom/orbit-components/lib/Card";

export function UserDetails() {
  const { userId } = useParams<Record<string, string>>();
  const { user, isLoading, error } = useGetUser(userId);

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    throw error;
  }

  const { firstName, lastName, picture, gender, location, dateOfBirth, email, registerDate, phone } = user as User;
  const userFullName = `${firstName} ${lastName}`;

  return (
    <>
      <Helmet>
        <title>{userFullName}</title>
      </Helmet>
      <Card spaceAfter="small">
        <CardSection>
          <ProfileDetails>
            <Avatar src={picture} alt={userFullName} />
            <Heading align="center" type="title2">
              {userFullName}
            </Heading>
            <span>{location.country}</span>
          </ProfileDetails>
        </CardSection>
      </Card>

      <Card spaceAfter="small">
        <CardSection title="Personal details">
          <AccountDetails>
            <li>
              <span>Gender</span>
              <span>{gender}</span>
            </li>
            <li>
              <span>Birth date</span>
              <span>{Dates.Format(dateOfBirth, "YYYY-MM-DD")}</span>
            </li>
            <li>
              <span>Registration date</span>
              <span>{Dates.Format(registerDate, "YYYY-MM-DD")}</span>
            </li>
          </AccountDetails>
        </CardSection>
      </Card>

      <Card spaceAfter="small">
        <CardSection title="Contact details">
          <AccountDetails>
            <li>
              <span>Phone</span>
              <span>{phone}</span>
            </li>
            <li>
              <span>E-mail</span>
              <span>{email}</span>
            </li>
          </AccountDetails>
        </CardSection>
      </Card>

      <Card spaceAfter="small">
        <CardSection title="Location details">
          <AccountDetails>
            <li>
              <span>Country</span>
              <span>{location.country}</span>
            </li>
            <li>
              <span>Timezone</span>
              <span>{location.timezone}</span>
            </li>
            <li>
              <span>State</span>
              <span>{location.state}</span>
            </li>
            <li>
              <span>City</span>
              <span>{location.city}</span>
            </li>
            <li>
              <span>Street</span>
              <span>{location.street}</span>
            </li>
          </AccountDetails>
        </CardSection>
      </Card>
    </>
  );
}
