import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import Button from "@kiwicom/orbit-components/lib/Button";
import Heading from "@kiwicom/orbit-components/lib/Heading";
import Loading from "@kiwicom/orbit-components/lib/Loading";
import { PaginationControl } from "@/common/styles/Pagination";
import { getPostsByTag } from "@/modules/Posts/services/postsService";
import { useGetPaginatedData } from "@/common/hooks/useGetPaginatedData";
import { PostItem } from "@/modules/Posts/components/PostItem/PostItem";

export function PostsByTag() {
  const { tagId } = useParams<Record<string, string>>();
  const { isLoading, error, items: posts, loadMore } = useGetPaginatedData(getPostsByTag, tagId, 5);
  const tag = `${tagId.charAt(0).toUpperCase()}${tagId.substring(1, tagId.length)}`;

  if (error) {
    throw error;
  }

  // First loading state
  if (isLoading && !posts.length) {
    return <Loading />;
  }

  const renderPosts = () => posts.map((post) => <PostItem showOwner={true} key={post.id} {...post} />);

  return (
    <>
      <Helmet>
        <title>{tag} tag</title>
      </Helmet>

      <Heading spaceAfter="medium" type="title2">
        {tag} tag
      </Heading>

      {renderPosts()}

      {!!posts.length && (
        <PaginationControl>
          <Button loading={isLoading} size="small" onClick={loadMore}>
            Load more
          </Button>
        </PaginationControl>
      )}
    </>
  );
}
