import axios from "axios";

const { REACT_APP_API, REACT_APP_API_ID } = process.env;

export const http = axios.create({
  baseURL: REACT_APP_API,
  headers: {
    "app-id": REACT_APP_API_ID as string,
  },
});
