import styled from "styled-components";
import * as Dates from "@/common/utils/dates";
import { Comment } from "@/modules/Posts/typings";

const Container = styled.article`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px 0;
`;

const Author = styled.span`
  font-size: 12px;
`;

const Message = styled.p`
  margin: 0;
`;

export function CommentItem({ message, owner, publishDate }: Comment) {
  if (!owner) {
    return null;
  }

  return (
    <Container>
      <Author>
        {owner.firstName} {owner.lastName}, {Dates.FromNow(publishDate)}
      </Author>
      <Message>{message}</Message>
    </Container>
  );
}
