import styled from "styled-components";

export const Avatar = styled.img`
  width: 100px;
  margin: 0 auto;
  display: block;
  border-radius: 50%;
  margin-bottom: 20px;
`;

export const ProfileDetails = styled.div`
  text-align: center;
`;

export const AccountDetails = styled.ul`
  padding: 0;
  list-style: none;

  li {
    padding: 10px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    span {
      display: block;
      text-transform: capitalize;
    }

    span:first-child {
      font-weight: bold;
    }
  }
`;
