import { http } from "@/common/utils/http";
import { PostPreview } from "@/modules/Posts/typings/index";
import { User, UserPreview } from "@/modules/Users/typings";
import { PaginateResponse } from "@/common/typing/interfaces";

export async function getUsers(limit: number, page: number, payload: unknown) {
  const params = { limit, page };
  const { data } = await http.get<PaginateResponse<UserPreview>>("/user", { params });
  return data;
}

export async function getUser(userId: string): Promise<User> {
  const { data } = await http.get<User>(`/user/${userId}`);
  return data;
}

export async function countPostsByUser(userId: string): Promise<number> {
  const { data } = await http.get<PaginateResponse<PostPreview>>(`/user/${userId}/post`);
  return data.total;
}
