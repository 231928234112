import { useState, useEffect } from "react";
import { ResponseItem } from "@/common/typing/interfaces";
import { PaginateResponse } from "@/common/typing/interfaces";

interface RequestorHandler<T extends ResponseItem> {
  (limit: number, page: number, payload: unknown): Promise<PaginateResponse<T>>;
}

/**
 * // TO BE DOCUMENTEDs
 * @param requestHandler -
 * @param payload - any custom argument to be passed into the the request handler: userId, postId, tagId
 * @param limit -
 * @param page -
 * @returns
 */
export function useGetPaginatedData<T extends ResponseItem>(
  requestHandler: RequestorHandler<T>,
  payload: unknown,
  limit = 10,
  page = 0
) {
  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState<T[]>([]);
  const [error, setError] = useState<unknown>();
  const [currentPage, setCurrentPage] = useState(page);
  const [totalItems, setTotalItems] = useState(0);

  // Prevent memory leaks and restart items on payload change. E.g.: userId, postId, tagId change
  useEffect(() => {
    setItems([]);
  }, [payload]);

  useEffect(() => {
    const init = async () => {
      // TO REVIEW
      // Remove duplicates during development on reaload after saving the file
      const removeDuplicates = (_items: T[], data: T[]) => {
        return [..._items, ...data.filter(({ id }) => !_items.find((item) => item.id === id))];
      };

      setIsLoading(true);

      try {
        // Executes request handler passed as an argument
        const { data, total } = await requestHandler(limit, currentPage, payload);
        setTotalItems(total);
        setItems((_items: T[]) => removeDuplicates(_items, data));
      } catch (error: unknown) {
        setError(error);
      }

      setIsLoading(false);
    };
    init();
  }, [limit, page, currentPage, requestHandler, payload]);

  const loadMore = () => {
    console.log("Load more posts", totalItems, items.length);
    // Only set the next page as long as users items hasn't reached the total in the db
    if (items.length !== totalItems) {
      setCurrentPage(currentPage + 1);
    }
  };

  return { items, isLoading, error, loadMore };
}
