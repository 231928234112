import { useEffect, useState } from "react";
import { countPostsByUser } from "@/modules/Users/services/usersService";

export function useCountPostsByUser(userId: string) {
  const [count, setCount] = useState(0);
  const [errorCountPostsByUser, setErrorCountPostsByUser] = useState<unknown>();
  const [isLoadingCountPostsByUser, setIsLoadingCountPostsByUser] = useState(true);

  useEffect(() => {
    const init = async () => {
      setIsLoadingCountPostsByUser(true);
      try {
        const data = await countPostsByUser(userId);
        setCount(data);
      } catch (error) {
        setErrorCountPostsByUser(error);
      }
      setIsLoadingCountPostsByUser(false);
    };

    init();
  }, [userId]);

  return { count, errorCountPostsByUser, isLoadingCountPostsByUser };
}
