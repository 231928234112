import { useEffect, useState } from "react";
import { Comment } from "@/modules/Posts/typings";
import { getPostComments } from "@/modules/Posts/services/postsService";

export function useGetPostComments(postId: string) {
  const [total, setTotal] = useState(0);
  const [comments, setComments] = useState<Comment[]>([]);
  const [commentsError, setCommentsError] = useState<unknown>();
  const [isLoadingComments, setIsLoadingComments] = useState(true);

  useEffect(() => {
    const init = async () => {
      setIsLoadingComments(true);
      try {
        const data = await getPostComments(postId);
        setComments(data.comments.splice(0, 2));
        setTotal(data.total);
      } catch (error) {
        setCommentsError(error);
      }
      setIsLoadingComments(false);
    };
    init();
  }, [postId]);

  return { total, comments, isLoadingComments, commentsError };
}
