import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

export function Format(value: string, format: string) {
  return value ? dayjs(value).format(format) : "";
}

export function FromNow(value: string) {
  return value ? dayjs(value).fromNow() : "";
}
