import { Link } from "react-router-dom";
import { TagList } from "../TagList/TagList";
import { PostPreview } from "@/modules/Posts/typings";
import Alert from "@kiwicom/orbit-components/lib/Alert";
import Loading from "@kiwicom/orbit-components/lib/Loading";
import Heading from "@kiwicom/orbit-components/lib/Heading";
import { Content, Author, CommentsContainer, PostImage } from "./styles";
import Card, { CardSection } from "@kiwicom/orbit-components/lib/Card";
import { useGetPostComments } from "@/modules/Posts/hooks/useGetPostComments";
import { CommentItem } from "@/modules/Posts/components/CommentItem/CommentItem";

interface Props extends PostPreview {
  showOwner: boolean;
}

export function PostItem({ text, image, tags, id, owner }: Props) {
  const { comments, isLoadingComments, commentsError, total } = useGetPostComments(id);

  if (commentsError) {
    return <Alert type="critical" title="An error has occurred trying to fetch this post's comments" />;
  }

  const renderComments = () => {
    return comments.map((comment) => <CommentItem key={comment.id} {...comment} />);
  };

  return (
    <Card spaceAfter="medium">
      <CardSection>
        <Author>
          Posted by <Link to={`/users/${owner.id}`}>{owner.firstName}</Link>
        </Author>

        <PostImage loading="lazy" src={image} alt={text} />

        <Content>{text}</Content>

        <TagList tags={tags}></TagList>

        <CommentsContainer>
          <Heading spaceAfter="small" type="title3">
            {total} Comment(s)
          </Heading>

          {isLoadingComments ? <Loading type="inlineLoader" /> : renderComments()}
        </CommentsContainer>
      </CardSection>
    </Card>
  );
}
