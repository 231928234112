import styled from "styled-components";
import { Link } from "react-router-dom";
import { UserPreview } from "@/modules/Users/typings";
import Button from "@kiwicom/orbit-components/lib/Button";
import Heading from "@kiwicom/orbit-components/lib/Heading";
import Loading from "@kiwicom/orbit-components/lib/Loading";
import ButtonGroup from "@kiwicom/orbit-components/lib/ButtonGroup";
import Card, { CardSection } from "@kiwicom/orbit-components/lib/Card";
import { useCountPostsByUser } from "@/modules/Users/hooks/useCountPostsByUser";

interface Props extends UserPreview {}

const Avatar = styled.img`
  width: 80px;
  margin-right: 20px;
  border-radius: 50%;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
`;

const TotalPosts = styled.span`
  font-size: 12px;
  padding: 2px 5px;
  border-radius: 2px;
  background-color: #eee;
`;

function ButtonLink({ url, label }: Record<string, string>) {
  return (
    <Button size="small">
      <Link style={{ color: "#fff", textDecoration: "none" }} to={url}>
        {label}
      </Link>
    </Button>
  );
}

export function UserItem({ firstName, lastName, picture, id }: Props) {
  const name = `${firstName} ${lastName}`;
  const { count, isLoadingCountPostsByUser } = useCountPostsByUser(id);

  return (
    <Card>
      <CardSection>
        <Content>
          <Avatar loading="lazy" src={picture} alt={name} />
          <div>
            {isLoadingCountPostsByUser ? <Loading type="inlineLoader" /> : <TotalPosts>{count} post(s)</TotalPosts>}
            <Heading spaceAfter="small" type="title4">
              <span data-testid="userItemName">{name}</span>
            </Heading>
            <ButtonGroup>
              <ButtonLink url={`/users/${id}`} label="View profile"></ButtonLink>
              <ButtonLink url={`/posts/users/${id}`} label="View posts"></ButtonLink>
            </ButtonGroup>
          </div>
        </Content>
      </CardSection>
    </Card>
  );
}
