import { useEffect, useState } from "react";
import { User } from "@/modules/Users/typings";
import { getUser } from "@/modules/Users/services/usersService";

export function useGetUser(userId: string) {
  const [user, setUser] = useState<User | null>(null);
  const [error, setError] = useState<unknown>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      try {
        const data = await getUser(userId);
        setUser(data);
      } catch (_error) {
        setError(_error);
      }
      setIsLoading(false);
    };
    init();
  }, [setUser, userId]);

  return { user, error, isLoading };
}
