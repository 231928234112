import styled from "styled-components";
import { Link, NavLink } from "react-router-dom";
import Heading from "@kiwicom/orbit-components/lib/Heading";
import LinkList from "@kiwicom/orbit-components/lib/LinkList";
import TextLink from "@kiwicom/orbit-components/lib/TextLink";
import Layout, { LayoutColumn } from "@kiwicom/orbit-components/lib/Layout";

const Container = styled.header`
  width: 100%;
  padding: 10px 0;
  margin-bottom: 20px;
  background-color: var(--color-white);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

const Nav = styled.nav`
  margin-top: 20px;
`;

export function Header() {
  return (
    <Container>
      <Layout type="MMB">
        <LayoutColumn>
          <Heading>
            <Link style={{ textDecoration: "none", color: "initial" }} to="/">
              GetHarley's blog
            </Link>
          </Heading>

          <Nav>
            <LinkList direction="row">
              <NavLink activeClassName="header__nav--active" to="/users">
                <TextLink asComponent="span">Users</TextLink>
              </NavLink>
            </LinkList>
          </Nav>
        </LayoutColumn>
      </Layout>
    </Container>
  );
}
