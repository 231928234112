import "@/common/styles/global.css";
import { ErrorBoundary } from "react-error-boundary";
import { Header } from "@/common/components/Header/Header";
import { Helmet, HelmetProvider } from "react-helmet-async";
import getTokens from "@kiwicom/orbit-components/lib/getTokens";
import ThemeProvider from "@kiwicom/orbit-components/lib/ThemeProvider";
import { UsersList } from "@/modules/Users/containers/UsersList/UsersList";
import { ErrorContainer } from "@/containers/ErrorContainer/ErrorContainer";
import Layout, { LayoutColumn } from "@kiwicom/orbit-components/lib/Layout";
import { PostsByTag } from "@/modules/Posts/containers/PostsByTag/PostsByTag";
import { UserDetails } from "@/modules/Users/containers/UserDetails/UserDetails";
import { PostsByUser } from "@/modules/Posts/containers/PostsByUser/PostsByUser";
import { Route, Switch, Redirect, BrowserRouter as Router } from "react-router-dom";

function App() {
  const customTokens = getTokens({});

  return (
    <HelmetProvider>
      <ThemeProvider theme={{ orbit: customTokens }}>
        <Router>
          <main>
            <Helmet>
              <title>GetHarley's Blog</title>
            </Helmet>

            <Header></Header>

            <Layout type="MMB">
              <LayoutColumn>
                <ErrorBoundary FallbackComponent={ErrorContainer}>
                  <Switch>
                    <Route exact path="/">
                      <Redirect to="/users"></Redirect>
                    </Route>
                    <Route exact path="/users">
                      <UsersList></UsersList>
                    </Route>
                    <Route exact path="/users/:userId">
                      <UserDetails></UserDetails>
                    </Route>
                    <Route exact path="/posts/users/:userId">
                      <PostsByUser></PostsByUser>
                    </Route>
                    <Route exact path="/posts/tags/:tagId">
                      <PostsByTag></PostsByTag>
                    </Route>
                  </Switch>
                </ErrorBoundary>
              </LayoutColumn>
            </Layout>
          </main>
        </Router>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
