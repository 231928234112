import styled from "styled-components";

export const PostImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
`;

export const CommentsContainer = styled.div`
  margin-top: 20px;
`;

export const Author = styled.span`
  display: block;
  font-size: 12px;
  margin-bottom: 10px;
`;

export const Content = styled.p`
  margin-bottom: 20px;
`;
